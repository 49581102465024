import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { EventValidationComponent } from './modules/admin/event-validation/event-validation.component';
import { PageQrCodeValidationComponent } from './modules/admin/page-qr-code-validation/page-qr-code-validation.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'sign-in' },

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'users' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () =>
                    import(
                        'app/modules/auth/confirmation-required/confirmation-required.routes'
                    ),
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.routes'
                    ),
            },
            {
                path: 'reset-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/reset-password/reset-password.routes'
                    ),
            },
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.routes'),
            },
            {
                path: 'sign-up',
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.routes'),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.routes'),
            },
            {
                path: 'unlock-session',
                loadChildren: () =>
                    import(
                        'app/modules/auth/unlock-session/unlock-session.routes'
                    ),
            },
        ],
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'home',
                loadChildren: () =>
                    import('app/modules/landing/home/home.routes'),
            },
        ],
    },

    // Validation qrcode routes
    {
        path: '',
        component: PageQrCodeValidationComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'qrcode',
                loadChildren: () =>
                    import(
                        'app/modules/admin/page-qr-code-validation/page-qr-code-validation.routes'
                    ),
            },
        ],
    },
    //Event validation routes
    {
        path: '',
        component: EventValidationComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'event/invitation',
                loadChildren: () =>
                    import(
                        'app/modules/admin/event-validation/event-validation.component.routes'
                    ),
            },
        ],
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver,
        },
        children: [
            {
                path: 'users',
                loadChildren: () =>
                    import('app/modules/admin/user/user.routes'),
            },
            {
                path: 'services',
                loadChildren: () =>
                    import('app/modules/admin/service/service.routes'),
            },
            {
                path: 'entry-points',
                loadChildren: () =>
                    import('app/modules/admin/entry-point/entry-point.routes'),
            },
            {
                path: 'clients',
                loadChildren: () =>
                    import('app/modules/admin/client/client.routes'),
            },
            {
                path: 'roles',
                loadChildren: () =>
                    import('app/modules/admin/role/role.routes'),
            },
            {
                path: 'visites',
                loadChildren: () =>
                    import('app/modules/admin/visit/visit.routes'),
            },
            {
                path: 'visits-control',
                loadChildren: () =>
                    import(
                        'app/modules/admin/visit-control/visit-control.routes'
                    ),
            },
            {
                path: 'evenements',
                loadChildren: () =>
                    import('app/modules/admin/event/event.routes'),
            },
            {
                path: 'evenements-control',
                loadChildren: () =>
                    import(
                        'app/modules/admin/event-control/event-control.routes'
                    ),
            },
            {
                path: 'groupes',
                loadChildren: () =>
                    import('app/modules/admin/group/group.routes'),
            },
            {
                path: 'reportings',
                loadChildren: () =>
                    import(
                        'app/modules/admin/dashboards/project/project.routes'
                    ),
            },
            {
                path: 'travel',
                loadChildren: () =>
                    import('app/modules/admin/travel/travel.routes'),
            },
            {
                path: 'events-reporting',
                loadChildren: () =>
                    import(
                        'app/modules/admin/dashboards/events-project/events-project.routes'
                    ),
            },
        ],
    },
];
